import {
  Box,
  Card,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import ProductImage from "../products/productImage";
import ProductLabel from "../products/productLabel";
import {
  getDisplayPrice,
  getLabelList,
  getStock,
} from "../products/productUtils";
import ShopQuantityControl from "./shopQuantityControl";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
  },
  brand: {
    textTransform: "capitalize",
  },
  productTitle: {
    paddingInline: theme.spacing(4),
    wordWrap: "break-word",
    fontSize: "18px",
  },
  productPrice: {
    textAlign: "center",
    fontSize: "15px",
  },
  buyButton: {
    padding: 8,
  },
  buyButtonGrid: {
    textAlign: "end",
  },
  productLabels: {
    marginBlockStart: -15,
  },
  regionalLabel: {
    position: "absolute",
    paddingInline: 6,
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

function ProductCard({
  index,
  product,
  showStock,
  onCardClick,
  onQuantityChange = () => {},
  showBuyButton = false,
  showRegionalLabels = true,
}) {
  const mobile = useMediaQuery("(max-width:600px)");
  const classes = useStyles();

  return (
    <Card className={classes.card} onClick={(e) => onCardClick(product)}>
      <Grid container style={{ height: "100%" }} alignContent="space-between">
        <Grid item container xs={12}>
          <Box
            hidden={!product.regionalType}
            className={classes.regionalLabel}
            borderRadius={2}
          >
            <Typography variant="body1">Regional</Typography>
          </Box>
          <Grid
            style={mobile ? { marginTop: 15, marginBottom: 15 } : {}}
            item
            xs={12}
          >
            <ProductImage
              src={
                product.imagePath !== null
                  ? window._env_.RS_HOST.concat(product.imagePath)
                  : null
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ProductLabel
              className={classes.productLabels}
              labels={getLabelList(product, showRegionalLabels)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="caption"
            component="p"
            align="center"
            className={classes.brand}
          >
            {product.brand && product.brand.toLowerCase()}
          </Typography>
          <Typography
            align="center"
            className={classes.productTitle}
            gutterBottom
          >
            {product.title}
          </Typography>
          <Typography hidden={!showStock}>{product.description}</Typography>
        </Grid>
        <Grid item container xs={12} alignContent="flex-end">
          <Grid item xs={12}>
            <Typography className={classes.productPrice}>
              {getDisplayPrice(
                product.priceUnit,
                product.pricePerUnit,
                product.taxes
              )}
            </Typography>
            <Typography
              className={classes.productPrice}
              hidden={!showStock}
              color={product.stock > 0 ? "secondary" : "error"}
            >
              {getStock(product.priceUnit, product.stocktype, product.stock)}
            </Typography>
          </Grid>
          <Grid xs={12} className={classes.buyButtonGrid} item>
            {showBuyButton && product.stock > 0 && (
              <ShopQuantityControl
                index={index}
                product={product}
                onQuantityChange={onQuantityChange}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ProductCard;
