/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ShoppingCart } from "@material-ui/icons";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../App";
import { LOG_LEVEL } from "../../enums/log";
import { FILTER } from "../../enums/product";
import { useAuth } from "../../provider/authProvider";
import { theme } from "../../theme";
import { useStorage } from "../../useStorage";
import ProductDetailDialog from "../products/productDetailDialog";
import DataArea from "../utilities/dataArea";
import FilterPanel from "../utilities/filterPanel";
import Logger from "../utilities/logger";
import {
  formatNumber,
  getNumberOfDecimalPlaces,
} from "../utilities/numberUtils";
import SearchBar from "../utilities/searchBar";
import useInfiniteScroll from "../utilities/useInfiniteScroll";
import useScroll from "../utilities/useScroll";
import { getProduct, isCodedBarcode } from "./deliveryAction";
import DeliveryCard from "./deliveryCard";
import FeedbackPopover from "./feedbackPopover";
import LoginPopover from "./loginPopover";
import StoragePopover from "./storagePopover";

// eslint-disable-next-line no-unused-vars
const logger = new Logger({ level: LOG_LEVEL.INFO, target: "productlist" });
const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: "flex",
    flexDirection: "column",
  }),
  list: {
    // width: "100%",
    marginTop: 8,
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    flex: 2,
  },
  expandIcon: {
    width: "100%",
    marginBottom: -20,
    minHeight: 20,
    textAlign: "center",
  },
  filterArea: (props) => ({
    position: "fixed",
    top: props.offset + "rem",
    left: 0,
    right: 0,
    padding: 12,
    background: theme.palette.background.default,
    zIndex: 100,
    transition: "top 0.4s ease-out",
  }),
  filterAreaPlaceholder: (props) => ({
    height:
      props.scrollPosition <= 0 && props.filterAreaRef.current
        ? props.filterAreaRef.current.clientHeight
        : 0,
  }),
  hideFilterAreaButtonContainer: (props) => ({
    position: "fixed",
    top:
      props.offset * 16 +
      (props.filterAreaRef.current
        ? props.filterAreaRef.current.clientHeight
        : 0) -
      12,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    zIndex: 1001,
  }),
  hideFilterAreaButton: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  filter: {
    marginBlockStart: 5,
  },
  filterButton: (props) => ({
    padding: 0,
    minWidth: `calc(100% / ${props.filterLength})`,
    fontSize: 16,
  }),
  addedToCartSnackbar: (props) => ({
    top: props.offset * 16,
  }),
}));

function DeliveryList({
  products,
  filter, // alphabet or category
  searchBar,
  showStock,
  onListItemClick,
  onListItemQuantityChange,
  offset,
  fullscreen,
  infiniteScroll,
  showBuyButton = false,
  showShoppingCart = false,
  showRegionalLabels = true,
  useListAnchor = false,
  useListHeader = false,
}) {
  const auth = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  const mobile = useMediaQuery("(max-width:600px)");
  const { state } = useContext(AppContext);
  // TODO: Start loading before reaching ref
  const { loaderRef, page } = useInfiniteScroll();
  const { scrollDirection, scrollPosition } = useScroll();
  const filterAreaRef = useRef();
  const [hideFilterArea, setHideFilterArea] = useState(false);
  const [currentPath, setCurrentPath] = useState(null);
  const [previousPath, setPreviousPath] = useState(null);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [filteredDeliverableProducts, setFilteredDeliverableProducts] =
    useState(products);
  const [filteredStoreProducts, setFilteredStoreProducts] = useState(products);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [activeCategoryGroup, setActiveCategoryGroup] = useStorage(
    window.sessionStorage,
    "activeCategoryGroup",
    null
  );
  const [activeCategory, setActiveCategory] = useStorage(
    window.sessionStorage,
    "activeCategory",
    null
  );
  const [activeTag, setActiveTag] = useStorage(
    window.sessionStorage,
    "activeTag",
    null
  );
  const [searchValue, setSearchValue] = useStorage(
    window.sessionStorage,
    "searchValue",
    ""
  );
  const [index, setIndex] = useState(infiniteScroll ? 50 : products.length);
  const [showLoginPopover, setShowLoginPopover] = useStorage(
    window.sessionStorage,
    "showLoginPopover",
    true
  );
  const [addedToCartText, setAddedToCartText] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [feedbackPopoverAnchor, setFeedbackPopoverAnchor] = useState(null);
  const [storagePopoverAnchor, setStoragePopoverAnchor] = useState(null);
  const [codedBarcodeProduct, setCodedBarcodeProduct] = useState(null);
  const [loginPopoverAnchor, setLoginPopoverAnchor] = useState(null);
  const [afterLoginPopover, setAfterLoginPopover] = useState(() => {});

  let currentHeader = "";
  let filterIndex = -1;

  const classes = useStyles({
    offset: offset,
    filterLength:
      filter === FILTER.ALPHABET
        ? alphabet.length
        : filter === FILTER.CATEGORY
        ? categories.size
        : 0,
    fullscreen: fullscreen,
    smallScreen: mobile,
    filterAreaRef: filterAreaRef,
    scrollPosition: scrollPosition,
  });

  useEffect(() => {
    setCategories([
      ...new Map(
        products
          .flatMap((product) => product.categories)
          .filter(Boolean)
          .map((category) => category && [category.name, category])
      ).values(),
    ]);
    setTags([
      ...new Set(products.flatMap((product) => product.tags).filter(Boolean)),
    ]);
  }, [products]);

  useEffect(() => {
    if (infiniteScroll) {
      setIndex(50);
    } else {
      setIndex(products.length);
    }
    setFilteredDeliverableProducts(
      products
        .filter((product) => product.deliverable)
        .filter((product) => filterProduct(product))
    );
    setFilteredStoreProducts(
      products
        .filter((product) => !product.deliverable)
        .filter((product) => filterProduct(product))
    );
  }, [products, searchValue, activeCategoryGroup, activeCategory, activeTag]);

  useEffect(() => {
    setHideFilterArea(scrollDirection === "down");
  }, [scrollDirection]);

  useEffect(() => {
    setPreviousPath(currentPath);
    setCurrentPath(location.pathname);
  }, [location]);

  useEffect(() => {
    if (!location.search.includes("product=")) {
      setSelectedProduct(null);
    } else {
      const searchParams = new URLSearchParams(location.search);
      const productId = searchParams.get("product");
      if (
        productId !== "null" &&
        productId !== "undefined" &&
        productId !== "" &&
        (!selectedProduct || selectedProduct.productId !== productId)
      ) {
        const product = getProduct(state, parseInt(productId));
        if (product) {
          setSelectedProduct(product);
        }
      }
    }
  }, [state, location]);

  useEffect(() => {
    if (
      location.search.includes("categoryGroup=") ||
      location.search.includes("category=") ||
      location.search.includes("tag=")
    ) {
      resetFilters();
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.has("categoryGroup")) {
        setActiveCategoryGroup(searchParams.get("categoryGroup"));
      }
      if (searchParams.has("category")) {
        setActiveCategory(searchParams.get("category"));
      }
      if (searchParams.has("tag")) {
        setActiveTag(searchParams.get("tag"));
      }
    }
  }, [location]);

  useEffect(() => {
    setIndex(page * 50);
  }, [page]);

  const filterProduct = (product) => {
    if (product !== null) {
      if (
        activeCategoryGroup !== null &&
        (!product.categoryGroups ||
          !product.categoryGroups.includes(activeCategoryGroup))
      ) {
        return false;
      }
      if (
        activeCategory !== null &&
        (!product.categories ||
          !product.categories
            .map((category) => category.name)
            .includes(activeCategory))
      ) {
        return false;
      }
      if (activeTag !== null) {
        if (activeTag === "REGIONAL") {
          if (!product.regionalType) {
            return false;
          }
        } else if (!product.tags || !product.tags.includes(activeTag)) {
          return false;
        }
      }
      if (searchValue.length > 0) {
        if (product.title.toLowerCase().includes(searchValue.toLowerCase())) {
          return true;
        }
        if (
          product.brand &&
          product.brand.toLowerCase().includes(searchValue.toLowerCase())
        ) {
          return true;
        }
        if (
          product.categories &&
          product.categories.some(
            (category) =>
              t(
                "category.".concat(category.name),
                category.name
              ).toLowerCase() === searchValue.toLowerCase()
          )
        ) {
          return true;
        }
        if (
          product.tags &&
          product.tags.some(
            (tag) =>
              t("tag.".concat(tag), tag).toLowerCase() ===
              searchValue.toLowerCase()
          )
        ) {
          return true;
        }
        return false;
      }
    }
    return true;
  };

  const listItemClick = (product) => {
    setSelectedProduct(product);
    onListItemClick(product);
    history.push(history.location.pathname + "?product=" + product.productId);
  };

  const getAnchor = (product) => {
    let anchors = [];
    let currentIndex =
      filter === FILTER.ALPHABET
        ? alphabet.length - 1
        : filter === FILTER.CATEGORY
        ? categories.size - 1
        : -1;
    let categoryArray = [];
    if (filter === FILTER.CATEGORY && categories !== null) {
      categoryArray = categories.map((category) => category.name);
    }
    if (filterProduct(product)) {
      if (product != null) {
        if (filter === FILTER.ALPHABET) {
          currentIndex = alphabet.indexOf(product.title[0].toLowerCase());
        } else if (filter === FILTER.CATEGORY) {
          currentIndex = categoryArray.indexOf(product.category);
        }
      }
      while (filterIndex !== currentIndex && currentIndex > -1) {
        filterIndex = filterIndex + 1;
        if (filter === FILTER.ALPHABET) {
          anchors.push(
            <Box id={alphabet[filterIndex]} key={alphabet[filterIndex]} />
          );
        } else if (filter === FILTER.CATEGORY) {
          anchors.push(
            <Box
              id={categoryArray[filterIndex]}
              key={categoryArray[filterIndex]}
            />
          );
        }
      }
    }
    return anchors;
  };

  const getHeader = (product) => {
    var title = replaceSpecialCharacters(product.title).toUpperCase();
    var newHeader = currentHeader;
    if (filterProduct(product)) {
      if (
        filter === FILTER.ALPHABET &&
        title[0] !== currentHeader &&
        alphabet.indexOf(title[0].toLowerCase()) > -1
      ) {
        if (
          title[0] !== currentHeader &&
          alphabet.indexOf(title[0].toLowerCase()) > -1
        ) {
          newHeader = title[0];
        }
      } else if (filter === FILTER.CATEGORY) {
        const header = t(
          "webProduct.category.".concat(product.category),
          product.category
        );
        if (header !== currentHeader) {
          newHeader = header;
        }
      }
    }
    if (newHeader !== currentHeader) {
      currentHeader = newHeader;
      return (
        <Grid key={"productListHeading" + currentHeader} item xs={12}>
          <Typography variant="body1">{currentHeader}</Typography>
        </Grid>
      );
    }
    return null;
  };

  const replaceSpecialCharacters = (title) => {
    title = title.toLowerCase();
    title = title.replace("ä", "ae");
    title = title.replace("ö", "oe");
    title = title.replace("ü", "ue");
    title = title.replace("ß", "ss");
    return title;
  };

  const resetFilters = () => {
    setActiveCategoryGroup(null);
    setActiveCategory(null);
    setActiveTag(null);
    setSearchValue("");
  };

  const search = (value) => {
    setSearchValue(value);
  };

  const changeQuantity = (index, target, quantity) => {
    const product = filteredDeliverableProducts[index];
    onQuantityChange(product, target, quantity);
  };

  const onQuantityChange = (product, target, quantity) => {
    let afterFunction = () => {
      if (quantity <= 0) {
        setAddedToCartText(product.title + " aus dem Warenkorb entfernt.");
      } else {
        setAddedToCartText(
          formatNumber(
            quantity,
            getNumberOfDecimalPlaces(product.quantitySteps)
          ) +
            (product.priceUnit === "WEIGHT" ? " kg " : " x ") +
            product.title +
            " im Warenkorb."
        );
      }
      onListItemQuantityChange(product, quantity);
    };
    if (product.stock < quantity) {
      afterFunction = () => {
        setFeedback("Du hast die maximal verfügbare Menge erreicht.");
        setFeedbackPopoverAnchor(target);
      };
    }
    if (isCodedBarcode(product)) {
      afterFunction = () => {
        setCodedBarcodeProduct(product);
        setStoragePopoverAnchor(target);
      };
    }
    if (!auth.loggedIn() && showLoginPopover) {
      setAfterLoginPopover(() => afterFunction);
      setLoginPopoverAnchor(target);
    } else {
      afterFunction();
    }
  };

  const closeProductDetailDialog = () => {
    if (previousPath !== currentPath) {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has("product")) {
        queryParams.delete("product");
        history.replace({ search: queryParams.toString() });
      }
    } else {
      history.goBack();
    }
  };

  const handleCategoryGroupChange = (categoryGroup) => {
    setActiveCategoryGroup(categoryGroup);
    setActiveCategory(null);
  };

  return (
    <Box className={classes.root}>
      {filter !== null && [
        <Box
          className={classes.filterArea}
          boxShadow={4}
          hidden={hideFilterArea}
          ref={filterAreaRef}
        >
          {searchBar ? (
            <SearchBar
              color="secondary"
              value={searchValue}
              onSearch={search}
            />
          ) : null}
          {filter === FILTER.ALPHABET ? (
            <ButtonGroup
              variant="text"
              color="primary"
              size="medium"
              aria-label="text primary button group"
            >
              {[...alphabet].map((letter) => (
                <Button
                  key={"filter" + letter}
                  className={classes.filterButton}
                  href={"#" + letter}
                >
                  {letter.toUpperCase()}
                </Button>
              ))}
            </ButtonGroup>
          ) : filter === FILTER.CATEGORY ? (
            [
              <DataArea
                key="categories"
                className={classes.filter}
                color="secondary"
                heading="Kategorien"
              >
                <FilterPanel
                  color="secondary"
                  activeColor="primary"
                  labels={
                    new Map(
                      categories.map((category) => [
                        category.group,
                        t(
                          "categoryGroup.".concat(category.group),
                          category.group
                        ),
                      ])
                    )
                  }
                  activeLabel={activeCategoryGroup}
                  onClick={handleCategoryGroupChange}
                />
                <Typography
                  variant="body2"
                  align="center"
                  hidden={!activeCategoryGroup}
                >
                  <ExpandMoreIcon color="disabled" fontSize="medium" />
                </Typography>
                <FilterPanel
                  color="secondary"
                  activeColor="primary"
                  labels={
                    new Map(
                      categories
                        .filter(
                          (category) =>
                            activeCategoryGroup !== null &&
                            category.group === activeCategoryGroup
                        )
                        .map((category) => [
                          category.name,
                          t("category.".concat(category.name), category.name),
                        ])
                    )
                  }
                  activeLabel={activeCategory}
                  onClick={setActiveCategory}
                />
              </DataArea>,
              <DataArea
                key="tags"
                className={classes.filter}
                color="secondary"
                heading="Besonderheiten"
              >
                <FilterPanel
                  color="secondary"
                  activeColor="primary"
                  labels={
                    new Map([
                      ["REGIONAL", t("tag.REGIONAL", "REGIONAL")],
                      ...tags.map((tag) => [tag, t("tag.".concat(tag), tag)]),
                    ])
                  }
                  activeLabel={activeTag}
                  onClick={setActiveTag}
                />
              </DataArea>,
            ]
          ) : null}
        </Box>,
        <Box className={classes.hideFilterAreaButtonContainer}>
          <IconButton
            variant="contained"
            size="small"
            className={classes.hideFilterAreaButton}
            onClick={() => setHideFilterArea(!hideFilterArea)}
          >
            {hideFilterArea ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Box>,
        <Box className={classes.filterAreaPlaceholder} />,
      ]}
      <Grid container spacing={theme.spacing(3)} className={classes.list}>
        {filteredDeliverableProducts
          .slice(
            0,
            index >= filteredDeliverableProducts.length
              ? filteredDeliverableProducts.length
              : index
          )
          .map((product, index) => [
            useListAnchor && getAnchor(product),
            useListHeader && getHeader(product),
            <Grid
              key={"deliverableProduct_" + index}
              container
              item
              xs={6}
              sm={showShoppingCart ? 12 : 4}
              md={showShoppingCart ? 12 : 3}
              lg={showShoppingCart ? 10 : 2}
              xl={showShoppingCart ? 10 : 2}
            >
              <DeliveryCard
                index={index}
                product={product}
                showStock={showStock}
                onCardClick={listItemClick}
                onQuantityChange={changeQuantity}
                showBuyButton={showBuyButton}
                showRegionalLabels={showRegionalLabels}
              />
            </Grid>,
          ])}
        {getAnchor(null)}
        {filteredDeliverableProducts.length <= 0 ? (
          <Grid key="searchNoResults" item xs={12}>
            <Typography variant="body1" align="center">
              Keine Produkte gefunden.
            </Typography>
          </Grid>
        ) : null}
        {index >= filteredDeliverableProducts.length &&
          filteredStoreProducts.length > 0 && [
            <Grid key="headerStoreProducts" item xs={12}>
              <Divider />
              <Typography align="center" color="primary">
                Diese Produkte können wir dir leider nicht liefern, du kannst
                sie aber bei uns im Laden kaufen.
              </Typography>
            </Grid>,
            filteredStoreProducts.map((product, index) => [
              <Grid
                key={"storeProduct_" + index}
                container
                item
                xs={6}
                sm={showShoppingCart ? 12 : 4}
                md={showShoppingCart ? 12 : 3}
                lg={showShoppingCart ? 10 : 2}
                xl={showShoppingCart ? 10 : 2}
              >
                <DeliveryCard
                  index={index}
                  product={product}
                  showStock={showStock}
                  onCardClick={listItemClick}
                  showBuyButton={false}
                  showRegionalLabels={showRegionalLabels}
                />
              </Grid>,
            ]),
          ]}
      </Grid>
      <Box
        className={classes.expandIcon}
        ref={infiniteScroll ? loaderRef : null}
        hidden={!infiniteScroll && !fullscreen}
      >
        <Typography
          variant="body1"
          hidden={
            !infiniteScroll || index >= filteredDeliverableProducts.length
          }
        >
          Lade weitere Produkte ...
        </Typography>
        {index < filteredDeliverableProducts.length && (
          <ExpandMoreIcon color="disabled" fontSize="large" />
        )}
      </Box>
      <ProductDetailDialog
        open={selectedProduct !== null}
        product={selectedProduct}
        onClose={closeProductDetailDialog}
        onQuantityChange={
          selectedProduct && selectedProduct.deliverable && onQuantityChange
        }
        showRegionalLabels={showRegionalLabels}
      />
      <LoginPopover
        anchor={loginPopoverAnchor}
        onLogin={() => {
          afterLoginPopover();
          auth.login();
        }}
        onContinue={() => {
          setShowLoginPopover(false);
          setLoginPopoverAnchor(null);
          afterLoginPopover();
        }}
        onClose={() => setLoginPopoverAnchor(null)}
      />
      <StoragePopover
        anchor={storagePopoverAnchor}
        product={codedBarcodeProduct}
        onClose={() => setStoragePopoverAnchor(null)}
      />
      <FeedbackPopover
        anchor={feedbackPopoverAnchor}
        feedback={feedback}
        onClose={() => setFeedbackPopoverAnchor(null)}
      />
      <Snackbar
        id="addedToCartSnackbar"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={addedToCartText !== null}
        autoHideDuration={6000}
        className={classes.addedToCartSnackbar}
        onClose={() => setAddedToCartText(null)}
      >
        <Alert
          id="addedToCartAlert"
          variant="filled"
          className={classes.alert}
          onClose={() => setAddedToCartText(null)}
          severity={
            addedToCartText && addedToCartText.endsWith("entfernt.")
              ? "error"
              : "success"
          }
          icon={
            addedToCartText && addedToCartText.endsWith("entfernt.") ? (
              <RemoveShoppingCartIcon fontSize="inherit" />
            ) : (
              <ShoppingCart fontSize="inherit" />
            )
          }
        >
          {addedToCartText}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default DeliveryList;
